/* Map container with enhanced styling */
.map-container {
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

/* Gradient overlay for the map */
.map-container .map-gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 10;
  opacity: 0.4;
  mix-blend-mode: overlay;
}

.map-container .map-gradient-overlay.light {
  background: radial-gradient(circle at top right, rgba(114, 20, 255, 0.1), transparent 70%),
              radial-gradient(circle at bottom left, rgba(199, 161, 255, 0.05), transparent 70%);
  opacity: 0.3;
  mix-blend-mode: multiply;
}

.map-container .map-gradient-overlay.dark {
  background: radial-gradient(circle at top right, rgba(114, 20, 255, 0.25), transparent 70%),
              radial-gradient(circle at bottom left, rgba(114, 20, 255, 0.15), transparent 70%),
              linear-gradient(to bottom right, rgba(114, 20, 255, 0.1) 0%, transparent 50%, rgba(114, 20, 255, 0.05) 100%);
  opacity: 0.5;
  mix-blend-mode: soft-light;
}

/* Corner decorations */
.map-container .map-corner-decoration {
  position: absolute;
  width: 40px;
  height: 40px;
  pointer-events: none;
  z-index: 11;
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

/* Dark mode specific corner decorations */
.dark .map-container .map-corner-decoration {
  opacity: 0.7;
  box-shadow: 0 0 10px rgba(114, 20, 255, 0.4);
}

/* Light mode specific corner decorations */
.light .map-container .map-corner-decoration {
  opacity: 0.4;
  box-shadow: 0 0 5px rgba(114, 20, 255, 0.2);
}

.map-container .map-corner-decoration.top-left {
  top: 0;
  left: 0;
  border-top: 2px solid rgba(114, 20, 255, 0.5);
  border-left: 2px solid rgba(114, 20, 255, 0.5);
  border-top-left-radius: 8px;
}

.map-container .map-corner-decoration.top-right {
  top: 0;
  right: 0;
  border-top: 2px solid rgba(114, 20, 255, 0.5);
  border-right: 2px solid rgba(114, 20, 255, 0.5);
  border-top-right-radius: 8px;
}

.map-container .map-corner-decoration.bottom-left {
  bottom: 0;
  left: 0;
  border-bottom: 2px solid rgba(114, 20, 255, 0.5);
  border-left: 2px solid rgba(114, 20, 255, 0.5);
  border-bottom-left-radius: 8px;
}

.map-container .map-corner-decoration.bottom-right {
  bottom: 0;
  right: 0;
  border-bottom: 2px solid rgba(114, 20, 255, 0.5);
  border-right: 2px solid rgba(114, 20, 255, 0.5);
  border-bottom-right-radius: 8px;
}

/* Dark mode specific corner borders */
.dark .map-container .map-corner-decoration.top-left,
.dark .map-container .map-corner-decoration.top-right,
.dark .map-container .map-corner-decoration.bottom-left,
.dark .map-container .map-corner-decoration.bottom-right {
  border-color: rgba(114, 20, 255, 0.7);
}

/* Light mode specific corner borders */
.light .map-container .map-corner-decoration.top-left,
.light .map-container .map-corner-decoration.top-right,
.light .map-container .map-corner-decoration.bottom-left,
.light .map-container .map-corner-decoration.bottom-right {
  border-color: rgba(114, 20, 255, 0.4);
}

/* Loading spinner animation */
.map-container .loading-spinner {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: conic-gradient(from 0deg, transparent 0%, transparent 25%, #7214FF 25%, #7214FF 75%, transparent 75%, transparent 100%);
  animation: map-spin 1.5s linear infinite;
  position: relative;
}

/* Dark mode spinner with enhanced glow */
.dark .map-container .loading-spinner {
  background: conic-gradient(from 0deg, transparent 0%, transparent 25%, #7214FF 25%, #7214FF 75%, transparent 75%, transparent 100%);
  box-shadow: 0 0 15px rgba(114, 20, 255, 0.5);
}

.map-container .spinner-inner {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  top: 5px;
  left: 5px;
}

.dark .map-container .spinner-inner {
  background-color: #0f172a;
}

@keyframes map-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Map loaded animation */
.map-container .map-loaded-animation {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at center, rgba(114, 20, 255, 0.2), transparent 70%);
  z-index: 9;
  pointer-events: none;
  animation: map-loaded 1.5s ease-out forwards;
}

/* Enhanced dark mode map loaded animation */
.dark .map-container .map-loaded-animation {
  background: radial-gradient(circle at center, rgba(114, 20, 255, 0.3), transparent 70%);
  animation: map-loaded-dark 1.8s ease-out forwards;
}

@keyframes map-loaded {
  0% {
    opacity: 0.6;
    transform: scale(0.9);
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}

@keyframes map-loaded-dark {
  0% {
    opacity: 0.9;
    transform: scale(0.9);
    box-shadow: inset 0 0 30px rgba(114, 20, 255, 0.4);
  }
  50% {
    opacity: 0.5;
    box-shadow: inset 0 0 50px rgba(114, 20, 255, 0.2);
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
    box-shadow: inset 0 0 70px rgba(114, 20, 255, 0);
  }
}

/* Pulse animation for markers */
@keyframes map-pulse {
  0% {
    transform: scale(0.8);
    opacity: 0.8;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.4;
  }
  100% {
    transform: scale(0.8);
    opacity: 0.8;
  }
}

/* Dark mode enhanced pulse */
@keyframes map-pulse-dark {
  0% {
    transform: scale(0.8);
    opacity: 0.9;
    box-shadow: 0 0 0 0 rgba(114, 20, 255, 0.7);
  }
  50% {
    transform: scale(1.3);
    opacity: 0.5;
    box-shadow: 0 0 0 10px rgba(114, 20, 255, 0);
  }
  100% {
    transform: scale(0.8);
    opacity: 0.9;
    box-shadow: 0 0 0 0 rgba(114, 20, 255, 0);
  }
}

.map-container .pulse-effect {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgba(114, 20, 255, 0.5);
  animation: map-pulse 2s infinite;
  z-index: 5;
}

.dark .map-container .pulse-effect {
  background-color: rgba(114, 20, 255, 0.7);
  animation: map-pulse-dark 2s infinite;
  box-shadow: 0 0 15px rgba(114, 20, 255, 0.5);
}

/* Custom map controls styling */
.map-container .gm-style .gm-style-iw-c {
  border-radius: 12px !important;
  padding: 16px !important;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  border-left: 4px solid #7214FF !important;
}

/* Dark mode info window */
.dark .map-container .gm-style .gm-style-iw-c {
  background-color: #0f172a !important;
  color: #ffffff !important;
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.3), 0 0 10px rgba(114, 20, 255, 0.3) !important;
  border-left: 4px solid #7214FF !important;
}

.dark .map-container .gm-style .gm-style-iw-d {
  background-color: #0f172a !important;
  color: #ffffff !important;
}

.map-container .gm-style .gm-style-iw-t::after {
  background: linear-gradient(45deg, #7214FF 50%, rgba(255, 255, 255, 0) 51%, rgba(255, 255, 255, 0) 100%) !important;
}

/* Dark mode arrow */
.dark .map-container .gm-style .gm-style-iw-t::after {
  background: linear-gradient(45deg, #7214FF 50%, rgba(15, 23, 42, 0) 51%, rgba(15, 23, 42, 0) 100%) !important;
}

/* Improve map controls */
.map-container .gm-style .gm-style-mtc button,
.map-container .gm-style .gm-fullscreen-control {
  border-radius: 8px !important;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  margin: 10px !important;
  transition: all 0.2s ease !important;
}

/* Dark mode controls */
.dark .map-container .gm-style .gm-style-mtc button,
.dark .map-container .gm-style .gm-fullscreen-control {
  background-color: #0f172a !important;
  color: #ffffff !important;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.2), 0 2px 4px -1px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(114, 20, 255, 0.3) !important;
}

/* Light mode controls */
.map-container .gm-style .gm-style-mtc button,
.map-container .gm-style .gm-fullscreen-control {
  background-color: #ffffff !important;
  color: #334155 !important;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06), 0 0 0 1px rgba(114, 20, 255, 0.1) !important;
}

.dark .map-container .gm-style .gm-style-mtc button.gm-control-active,
.dark .map-container .gm-style .gm-fullscreen-control:active {
  background-color: #7214FF !important;
}

.map-container .gm-style .gm-style-mtc button.gm-control-active,
.map-container .gm-style .gm-fullscreen-control:active {
  background-color: #7214FF !important;
  color: #ffffff !important;
}

.map-container .gm-style .gm-style-mtc button:hover,
.map-container .gm-style .gm-fullscreen-control:hover {
  transform: translateY(-2px) !important;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
}

/* Dark mode hover */
.dark .map-container .gm-style .gm-style-mtc button:hover,
.dark .map-container .gm-style .gm-fullscreen-control:hover {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.2), 0 4px 6px -2px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(114, 20, 255, 0.5) !important;
  background-color: #1e293b !important;
}

/* Light mode hover */
.map-container .gm-style .gm-style-mtc button:hover,
.map-container .gm-style .gm-fullscreen-control:hover {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(114, 20, 255, 0.3) !important;
  background-color: #f8fafc !important;
}

/* Zoom controls */
.map-container .gm-style .gm-bundled-control {
  margin: 10px !important;
}

.map-container .gm-style .gm-bundled-control .gmnoprint > div {
  border-radius: 8px !important;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  overflow: hidden !important;
  transition: all 0.2s ease !important;
}

/* Dark mode zoom controls */
.dark .map-container .gm-style .gm-bundled-control .gmnoprint > div {
  background-color: #0f172a !important;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.2), 0 2px 4px -1px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(114, 20, 255, 0.3) !important;
}

/* Light mode zoom controls */
.map-container .gm-style .gm-bundled-control .gmnoprint > div {
  background-color: #ffffff !important;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06), 0 0 0 1px rgba(114, 20, 255, 0.1) !important;
}

.dark .map-container .gm-style .gm-bundled-control .gmnoprint > div button {
  background-color: #0f172a !important;
  color: #ffffff !important;
}

.map-container .gm-style .gm-bundled-control .gmnoprint > div button {
  background-color: #ffffff !important;
  color: #334155 !important;
}

.dark .map-container .gm-style .gm-bundled-control .gmnoprint > div button:hover {
  background-color: #1e293b !important;
}

.map-container .gm-style .gm-bundled-control .gmnoprint > div button:hover {
  background-color: #f8fafc !important;
}

.dark .map-container .gm-style .gm-bundled-control .gmnoprint > div button:active {
  background-color: #7214FF !important;
}

.map-container .gm-style .gm-bundled-control .gmnoprint > div button:active {
  background-color: #7214FF !important;
  color: #ffffff !important;
}

.map-container .gm-style .gm-bundled-control .gmnoprint > div:hover {
  transform: translateY(-2px) !important;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
}

/* Dark mode zoom hover */
.dark .map-container .gm-style .gm-bundled-control .gmnoprint > div:hover {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.2), 0 4px 6px -2px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(114, 20, 255, 0.5) !important;
}

/* Light mode zoom hover */
.map-container .gm-style .gm-bundled-control .gmnoprint > div:hover {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(114, 20, 255, 0.3) !important;
} 