/* Overstyr ikonstørrelsen i Sidebar.Collapse når den er åpen i minimert visning */
.flex.fixed.top-0.left-0.z-20.flex-col button svg {
  width: 24px !important;
  height: 24px !important;
  min-width: 24px !important;
  min-height: 24px !important;
}

/* Spesielt for Collapse-knapper */
button[aria-expanded="true"] svg,
button[aria-expanded="false"] svg {
  width: 24px !important;
  height: 24px !important;
  min-width: 24px !important;
  min-height: 24px !important;
}

/* Sikre at alle ikoner i sidebaren har samme størrelse */
.flex.fixed.top-0.left-0.z-20.flex-col svg {
  min-width: 24px;
  min-height: 24px;
}

/* Gjør den minimerte sidebaren litt bredere og håndter scrollbaren bedre */
.flex.fixed.top-0.left-0.z-20.flex-col.rounded-none.flex-shrink-0[collapsed="true"] {
  width: 5.5rem !important; /* Standard er ca 4.5rem, øker til 5.5rem */
  overflow-x: hidden !important; /* Forhindre horisontal scrolling */
  /* Bruk en alternativ tilnærming for scrollbaren som ikke påvirker layouten */
  padding-right: 0 !important; /* Fjern padding som kan påvirke scrollbaren */
}

/* Bruk en container med overflow for å håndtere scrolling uten å påvirke layouten */
.flex.fixed.top-0.left-0.z-20.flex-col.rounded-none.flex-shrink-0[collapsed="true"] > div {
  width: 5.5rem !important; /* Samme bredde som sidebaren */
  box-sizing: border-box !important;
  overflow-y: auto !important; /* Aktiver scrolling på containeren i stedet */
  overflow-x: hidden !important;
  height: 100% !important;
  
  /* Skjul scrollbaren som standard, men behold funksjonaliteten */
  scrollbar-width: none !important; /* For Firefox */
  -ms-overflow-style: none !important; /* For IE og Edge */
}

/* Skjul scrollbaren for WebKit-baserte nettlesere (Chrome, Safari) */
.flex.fixed.top-0.left-0.z-20.flex-col.rounded-none.flex-shrink-0[collapsed="true"] > div::-webkit-scrollbar {
  width: 0 !important;
  background: transparent !important;
}

/* Vis scrollbaren kun ved hover for Firefox */
.flex.fixed.top-0.left-0.z-20.flex-col.rounded-none.flex-shrink-0[collapsed="true"]:hover > div {
  scrollbar-width: thin !important;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent !important;
}

/* Vis scrollbaren kun ved hover for WebKit-baserte nettlesere */
.flex.fixed.top-0.left-0.z-20.flex-col.rounded-none.flex-shrink-0[collapsed="true"]:hover > div::-webkit-scrollbar {
  width: 6px !important;
}

.flex.fixed.top-0.left-0.z-20.flex-col.rounded-none.flex-shrink-0[collapsed="true"]:hover > div::-webkit-scrollbar-track {
  background: transparent !important;
}

.flex.fixed.top-0.left-0.z-20.flex-col.rounded-none.flex-shrink-0[collapsed="true"]:hover > div::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2) !important;
  border-radius: 3px !important;
}

/* Gi mer plass til ikonene i minimert visning */
.flex.fixed.top-0.left-0.z-20.flex-col.rounded-none.flex-shrink-0[collapsed="true"] .flex.items-center.justify-center {
  padding: 0.75rem !important;
}

/* Gi mer plass mellom ikonene i minimert visning */
.flex.fixed.top-0.left-0.z-20.flex-col.rounded-none.flex-shrink-0[collapsed="true"] .space-y-1 > * {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

/* Sentrere ikonene bedre i minimert visning */
.flex.fixed.top-0.left-0.z-20.flex-col.rounded-none.flex-shrink-0[collapsed="true"] button {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0.75rem !important;
  width: 100% !important; /* Sikre at knappene fyller bredden */
  box-sizing: border-box !important;
}

/* Gi mer plass til undermenyene i Collapse når de er åpne i minimert visning */
.flex.fixed.top-0.left-0.z-20.flex-col.rounded-none.flex-shrink-0[collapsed="true"] [aria-expanded="true"] + div {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  width: 100% !important; /* Sikre at undermenyene fyller bredden */
}

/* Forbedre visningen av undermenyene i Collapse når de er åpne i minimert visning */
.flex.fixed.top-0.left-0.z-20.flex-col.rounded-none.flex-shrink-0[collapsed="true"] [aria-expanded="true"] + div a {
  justify-content: center !important;
  padding: 0.5rem !important;
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
  width: 100% !important; /* Sikre at undermeny-knappene fyller bredden */
  box-sizing: border-box !important;
}

/* Sikre at Sidebar.Items-containeren ikke blir skvist */
.flex.fixed.top-0.left-0.z-20.flex-col.rounded-none.flex-shrink-0[collapsed="true"] div[class*="flex flex-col"] {
  width: 100% !important;
  box-sizing: border-box !important;
}

/* Sikre at Sidebar.ItemGroup ikke blir skvist */
.flex.fixed.top-0.left-0.z-20.flex-col.rounded-none.flex-shrink-0[collapsed="true"] ul {
  width: 100% !important;
  box-sizing: border-box !important;
} 